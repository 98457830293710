var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-block"},[_c('menu-breadcrumb',[_c('el-breadcrumb-item',[_vm._v("團隊管理")])],1),_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v(" 團隊列表 "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),_c('div',[_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateGroup' })}}},[_vm._v("新增團隊 ")])],1)]),_c('el-table',{attrs:{"data":_vm.group_data}},[_c('el-table-column',{attrs:{"label":"ID","prop":"group_id","sortable":""}}),_c('el-table-column',{attrs:{"label":"中文姓名","prop":"group_zh.name","sortable":""}}),_c('el-table-column',{attrs:{"label":"英文姓名","prop":"group_en.name","sortable":""}}),_c('el-table-column',{attrs:{"fixed":"right","width":"260"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('el-input',{attrs:{"size":"mini","placeholder":"搜尋團隊名稱"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.setup.search),callback:function ($$v) {_vm.$set(_vm.setup, "search", $$v)},expression:"setup.search"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.handleSearch},slot:"append"})],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-setting"},on:{"click":function($event){return _vm.$router.push({
                name: 'DetailGroup',
                params: { id: row['group_id'] },
              })}}},[_vm._v("內容")]),_c('el-button',{attrs:{"size":"mini","type":"warning","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
                name: 'EditGroup',
                params: { id: row['group_id'] },
              })}}},[_vm._v("編輯")]),_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row['group_id'])}}},[_vm._v("刪除")])]}}])})],1),_c('table-pagination',{attrs:{"total":_vm.total,"limit":_vm.setup.limit,"page":_vm.setup.page},on:{"change-page":_vm.handleChangePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }