import request from "@/utils/request";

/**
 * Get groups with search features.
 *
 * @param {object} [params]
 * @param {string} params.search
 */
export function getGroups(params) {
  return request({
    url: "api/v1/groups",
    method: "GET",
    params,
  });
}

/**
 * Get groups with pagination and search features.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 * @param {string} [params.search]
 */
export function getGroupsPagination(params) {
  return request({
    url: "api/v1/groups/page",
    method: "GET",
    params,
  });
}

/**
 * Get group.
 *
 * @param {string} group_id
 */
export function getGroup(group_id) {
  return request({
    url: `api/v1/groups/${group_id}`,
    method: "GET",
  });
}

/**
 * Create group.
 *
 * @param {object} data
 * @param {File} data.photo
 * @param {number} data.category_id
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {string} data.description_zh
 * @param {string} data.description_en
 */
export function createGroup(data) {
  return request({
    url: "api/v1/groups",
    method: "POST",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * Update group.
 *
 * @param {string} group_id
 * @param {object} data
 * @param {File} [data.photo]
 * @param {number} data.category_id
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {string} data.description_zh
 * @param {string} data.description_en
 */
export function updateGroup(group_id, data) {
  return request({
    url: `api/v1/groups/${group_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete group.
 *
 * @param {string} group_id
 */
export function deleteGroup(group_id) {
  return request({
    url: `api/v1/groups/${group_id}`,
    method: "DELETE",
  });
}

/**
 * Add artist into group.
 *
 * @param {string} group_id
 * @param {string} artist_id
 */
export function addArtist(group_id, artist_id) {
  return request({
    url: `api/v1/groups/${group_id}/artists/${artist_id}`,
    method: "POST",
  });
}

/**
 * Delete artist in group.
 *
 * @param {string} group_id
 * @param {string} artist_id
 */
export function deleteArtist(group_id, artist_id) {
  return request({
    url: `api/v1/groups/${group_id}/artists/${artist_id}`,
    method: "DELETE",
  });
}

/**
 * @param {string} group_id
 * @param {string} artist_id
 * @param {object} data
 * @param {number} data.order
 */
export function updateGroupArtistOrder(group_id, artist_id, data) {
  return request({
    url: `api/v1/groups/${group_id}/artists/${artist_id}/order`,
    method: "PATCH",
    data,
  });
}

/**
 * Create work in group.
 *
 * @param {string} group_id
 * @param {string} work_id
 */
export function addGroupWork(group_id, work_id) {
  return request({
    url: `api/v1/groups/${group_id}/works/${work_id}`,
    method: "POST",
  });
}

/**
 * Delete work in group.
 *
 * @param {string} group_id
 * @param {string} work_id
 */
export function deleteGroupWork(group_id, work_id) {
  return request({
    url: `api/v1/groups/${group_id}/works/${work_id}`,
    method: "DELETE",
  });
}

/**
 * Create category in group.
 *
 * @param {string} group_id
 * @param {number} category_id
 */
export function addGroupCategory(group_id, category_id) {
  return request({
    url: `api/v1/groups/${group_id}/categories/${category_id}`,
    method: "POST",
  });
}

/**
 * Delete category in group.
 *
 * @param {string} group_id
 * @param {number} category_id
 */
export function deleteGroupCategory(group_id, category_id) {
  return request({
    url: `api/v1/groups/${group_id}/categories/${category_id}`,
    method: "DELETE",
  });
}
