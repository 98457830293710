<template>
  <div class="group-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>團隊管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          團隊列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <div>
          <el-button
            type="success"
            @click="$router.push({ name: 'CreateGroup' })"
            >新增團隊
          </el-button>
        </div>
      </table-title>

      <el-table :data="group_data">
        <el-table-column label="ID" prop="group_id" sortable></el-table-column>
        <el-table-column
          label="中文姓名"
          prop="group_zh.name"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文姓名"
          prop="group_en.name"
          sortable
        ></el-table-column>
        <el-table-column fixed="right" width="260">
          <template v-slot:header="{}">
            <el-input
              v-model="setup.search"
              size="mini"
              placeholder="搜尋團隊名稱"
              @keyup.enter.native="handleSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>

          <template v-slot="{ row }">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-setting"
              @click="
                $router.push({
                  name: 'DetailGroup',
                  params: { id: row['group_id'] },
                })
              "
              >內容</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="
                $router.push({
                  name: 'EditGroup',
                  params: { id: row['group_id'] },
                })
              "
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row['group_id'])"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import { deleteGroup, getGroupsPagination } from "@/api/group";

export default {
  name: "Group",
  data() {
    return {
      group_data: [],
      total: 0,
      setup: {
        limit: 25,
        page: 0,
        search: "",
      },
    };
  },
  created() {
    const { p, s } = this.$route.query;
    if (p) {
      this.setup.page = +p;
    }
    if (s) {
      this.setup.search = decodeURIComponent(s);
    }
    this.handleGetGroups();
  },
  methods: {
    async handleGetGroups() {
      const { results, total } = await getGroupsPagination(this.setup);

      this.total = total;
      this.group_data = results;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetGroups();
    },
    async handleSearch() {
      this.setup.page = 0;

      let query = {
        p: this.setup.page,
        s: encodeURIComponent(this.setup.search),
      };

      if (decodeURIComponent(query.s).length === 0) delete query.s;

      await this.$router.push({ query });

      await this.handleGetGroups();
    },
    handleDelete(group_id) {
      this.$confirm("是否刪除該團隊", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteGroup(group_id);
          await this.handleGetGroups();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
